import { docReady, getData } from './utils';

/* -------------------------------------------------------------------------- */
/*                             Redirection to Demo                            */
/* -------------------------------------------------------------------------- */

const redirectToDocs = (id) => {
  const input = document.getElementById(id);
  input &&
    input.addEventListener('change', (e) => {
      const el = e.currentTarget;
      const isChecked = el.checked;
      const { location } = window;
      isChecked
        ? location.replace(getData(el, 'pageUrl'))
        : location.replace(getData(el, 'homeUrl'));
    });
};

docReady(redirectToDocs('mode-rtl'));
docReady(redirectToDocs('mode-fluid'));
