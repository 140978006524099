/* -------------------------------------------------------------------------- */
/*                                  Copy LinK                                 */
/* -------------------------------------------------------------------------- */

const copyLink = () => {
  const copyLinkModal = document.getElementById('copyLinkModal');
  copyLinkModal &&
    copyLinkModal.addEventListener('shown.bs.modal', () => {
      const invitationLink = document.querySelector('.invitation-link');
      invitationLink.select();
    });
};

export default copyLink;
