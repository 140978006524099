import utils from './utils';

/* -------------------------------------------------------------------------- */
/*                                  flatpickr                                 */
/* -------------------------------------------------------------------------- */
const flatpickrInit = () => {
  if (window.flatpickr) {
    const datetimepickers = document.querySelectorAll('.datetimepicker');

    datetimepickers.forEach((datetimepicker) => {
      const options = utils.getData(datetimepicker, 'options');

      window.flatpickr(datetimepicker, {
        dateFormat: 'd/m/y',
        disableMobile: true,
        ...options,
      });
    });
  }
};

export default flatpickrInit;
