import utils from './utils';

/* -------------------------------------------------------------------------- */
/*                               Navbar Vertical                              */
/* -------------------------------------------------------------------------- */

const handleNavbarVerticalCollapsed = () => {
  const Selector = {
    HTML: 'html',
    NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
    NAVBAR_VERTOCAL_COLLAPSE: '.navbar-vertical .navbar-collapse',
    ECHART_RESPONSIVE: '[data-echart-responsive]',
  };

  const Events = {
    CLICK: 'click',
    MOUSE_OVER: 'mouseover',
    MOUSE_LEAVE: 'mouseleave',
  };
  const ClassNames = {
    NAVBAR_VERTOCAL_COLLAPSED: 'navbar-vertical-collapsed',
    NAVBAR_VERTOCAL_COLLAPSED_HOVER: 'navbar-vertical-collapsed-hover',
  };
  const navbarVerticalToggle = document.querySelector(
    Selector.NAVBAR_VERTICAL_TOGGLE
  );
  const html = document.querySelector(Selector.HTML);
  const navbarVerticalCollapse = document.querySelector(
    Selector.NAVBAR_VERTOCAL_COLLAPSE
  );

  if (navbarVerticalToggle) {
    navbarVerticalToggle.addEventListener(Events.CLICK, () => {
      html.classList.toggle(ClassNames.NAVBAR_VERTOCAL_COLLAPSED);
      // Refresh Echarts
      const $echarts = document.querySelectorAll(Selector.ECHART_RESPONSIVE);
      !!$echarts.length &&
        $echarts.forEach((item) => {
          if (!!utils.getData(item, 'echart-responsive')) {
            window.echarts.init(item).resize();
          }
        });

      // Set collapse state on localStorage
      const isNavbarVerticalCollapsed = utils.getItemFromStore(
        'isNavbarVerticalCollapsed'
      );
      utils.setItemToStore(
        'isNavbarVerticalCollapsed',
        !isNavbarVerticalCollapsed
      );
    });
  }
  if (navbarVerticalCollapse) {
    navbarVerticalCollapse.addEventListener(Events.MOUSE_OVER, () => {
      if (utils.hasClass(html, ClassNames.NAVBAR_VERTOCAL_COLLAPSED)) {
        html.classList.add(ClassNames.NAVBAR_VERTOCAL_COLLAPSED_HOVER);
      }
    });
    navbarVerticalCollapse.addEventListener(Events.MOUSE_LEAVE, () => {
      if (utils.hasClass(html, ClassNames.NAVBAR_VERTOCAL_COLLAPSED_HOVER)) {
        html.classList.remove(ClassNames.NAVBAR_VERTOCAL_COLLAPSED_HOVER);
      }
    });
  }
};

export default handleNavbarVerticalCollapsed;
